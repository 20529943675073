import React, { useState, useRef } from "react"
import { sendForm } from "./funcs"
import { Entry } from "./Entry"

const WordForm = () => {
    const form: any = {
        "entry.1936496141": "",
        "entry.1819477296": ""
    }
    const formURL = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfeDBDXpNneNCzX2alb8uwj9YcsOJcMA13rpUWnuVhKjljh_Q/formResponse"
    const [submitted, setSubmitted] = useState({flag: false, result: false})
    const [isFormCorrect, setFormCorrect] = useState<boolean|undefined>(false)

    const formElement = useRef<HTMLFormElement>(null)
    const sendButton = useRef<HTMLButtonElement>(null)
    
    const SubmitProcess = () => {
        const Process = async () => {
            const result = await sendForm(form, formURL)
            
            setSubmitted({flag: true, result: result})
            setTimeout(() => {
                window.location.reload()
            }, 3000) //3秒後に再読み込み
        }
        Process()
    }

    const changeData = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        form[e.target.name] = e.target.value
    }

    return (
        <>
        <p>
            こちらから不足している単語の造語を依頼できます。
        </p>
        <form className="wordForm" ref={formElement} onChange={(e) => {
            const formValidate = formElement.current?.checkValidity()
            setFormCorrect(formValidate)
        }}>
            <div className="formName">単語 (必須)</div>
            <div className="formDesc">※複数依頼する場合には、単語ごとに改行して入力してください。</div>
            <div className="formDesc">※造語依頼の内容は一般に公開される可能性があります。不適切な内容や個人情報は記入しないでください。</div>
            <textarea name="entry.1936496141" rows={5} className="inputForm" 
                onChange={changeData} required></textarea>

            <div className="formName">コメント (任意)</div>
            <textarea name="entry.1819477296" rows={5} className="inputForm" onChange={changeData}></textarea>

            {submitted.flag
                ? <></>
                : <button type="button" className={`submit ${isFormCorrect? "" : "disabled"}`} ref={sendButton} onClick={SubmitProcess} disabled={!isFormCorrect}>送信</button>
            }
        </form>
        {submitted.flag
            ? submitted.result
                ? <p>依頼を送信しました。3秒後にページをリロードします。</p>
                : <p>依頼の送信に失敗しました。3秒後にページをリロードします。</p>
            : <></>
        }
        </>
    )
}

export const aboutData = [
    {
        name: "このサイトについて",
        content: (<>
            <p>
                人工言語「
                <a href="https://kaeru2193.net/phunlang" target="_blank">雰語</a>
                」第三期のオンライン辞書です。
            </p>
            <p>
                雰語は
                <a href="https://kaeru2193.net">かえる</a>
                によって制作されています。不具合の報告や質問、その他の連絡は、 
                <a href="https://twitter.com/kaeru2193" target="_blank">X (Twitter)</a>
                 もしくは 
                <a href="https://discord.gg/tHhA4bBYap" target="_blank">公式Discordサーバー</a>
                 までお願いします。
            </p>
            <p>また、この辞書はCC BY-NC 4.0としてライセンスされています。辞書内のデータはライセンスの範囲内で使用することが可能です。</p>
        </>)
    },
    {
        name: "凡例",
        content: (<>
            <h2>書式</h2>
            <div>■見出し語■</div>
            <div><b>漢字転写</b> ⟨符号付きラテン文字転写⟩ ⟨数字付きラテン文字転写⟩ ⟨声字表記⟩</div>
            <div>【品詞1】[一] その品詞での語義1　[二] ‹タグ› その品詞での語義2...</div>
            <div>【品詞2】その品詞での語義　{">"}その品詞での語法</div>
            <div>【品詞3】その品詞での語義<br/>　[例文 漢字転写 発音<br/>　　例文和訳]</div>
            <div>　：</div>
            <div>【補遺1】[一] 補遺の内容1　[二] 補遺の内容2...</div>
            <div>【補遺2】補遺の内容</div>
            <div>　：</div>

            <h2>品詞</h2>
            <div>【名詞】</div>
            <div>【接頭辞】単語の頭に付いて新たな単語を形成するもの。</div>
            <div>【接尾辞】単語の末尾に付いて新たな単語を形成するもの。</div>
            <div>【代名詞】</div>
            <div>【動詞】</div>
            <div>【形容詞】</div>
            <div>【副詞】</div>
            <div>【助詞】</div>
            <div>【感動詞】</div>
            <div>【接続詞】</div>
            <div>【数詞】</div>
            <div>【助数詞】</div>
            <div>【情詞】</div>
            <div>【前置詞】</div>
            <div>【成句】ことわざや慣用句など。</div>
            <div>【造語成分】単体では用いられず、合成語の成分として用いられたときに持つ語義。</div>

            <h2>補遺</h2>
            <div>【語法】語の意味範囲や用法など。</div>
            <div>【類語】似た意味を持つ単語。（後ろにアスタリスクが付いているものは、その項目でより詳細な語義や語法が示されていることを表す。）</div>
            <div>【対義語】反対の意味を持つ単語。</div>
            <div>【関連語】上記2つには当てはまらないが、強い関連性を持つ単語。</div>
            <div>【世界観】文化など、単語が存在する背景。</div>
            <div>【文法】例外的な語順を用いる単語など、文法に関して特筆すべきこと。</div>
            <div>【改定】過去の単語改訂履歴のうち、重要なもの。</div>
            <div>【感想】辞書編纂者個人の感想。読む価値は乏しい。</div>

            <h2>タグ</h2>
            <div>〈数〉数学</div>
            <div>〈化〉化学</div>
            <div>〈生〉生物</div>
            <div>〈現〉現界にのみ存在する概念であり、雰界では使われない語。</div>
            <div>〈俗〉俗語やスラングなど、フォーマルな場面では使われない語。</div>
        </>)
    },
    {
        name: "造語依頼",
        content: (<>
            <WordForm/>
        </>)
    }
]